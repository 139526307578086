<template>
    <div class="container" id="about">
        <div class="left">
            <img src="../assets/images/avatar.png" alt="">
        </div>
        <div class="right">
            <div class="box">

                <div class="title">
                    <img src="../assets/images/about_tit.png" alt="">
                </div>
                <p>Welcome to LOOPY. We are a unified collective and community <br />
                    that is for protecting the good, privacy, and freedom of humanity <br />
                    LOOPY. Each of us is responsible for architecting the future of <br />
                    the world. Through our combined efforts, we can be a harmonious <br />
                    and collaborative community that helps society revert back to a <br />
                    decentralized world that creates freedom, fairness, and equal <br />
                    opportunity for every citizen regardless of race, status, or wealth.</p>
            </div>
        </div>
        <!-- <div class="pic1">
            <img src="../assets/images/about_pic1.png" alt="">
        </div> -->
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
}
</script>

<style lang="less" scoped>
.pic1 {
    position: absolute;
    right: 0;
    width: 12%;
    bottom: 0;

    img {
        width: 100%;
    }
}

.container {
    position: relative;
    width: 100%;
    background-image: url('../assets/images/aboutbg.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding: 80px;
    padding-top: 130px;
    padding-bottom: 0;

    .title {
        text-align: left;
        margin-bottom: 30px;
        padding-left: 20px;
        text-align: center;
        color: #fff;
        font-weight: bold;
        font-size: 38px;
        font-family: 'unb_lig';

        img {
            width: 34%;
        }
    }

    .left {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        position: relative;

        img {
            width: 65%;
        }
    }

    .right {
        display: flex;
        justify-content: start;
        width: 50%;
        align-items: center;
        position: relative;

        .box {
            width: 90%;
            backdrop-filter: blur(3px);
            padding: 120px 30px;

            p {
                font-size: 16px;
                color: #C4C4C4;
                word-break: break-all;
                font-family: 'Inter';
                line-height: 32px;
                text-align: left;
                font-size: 20px;
                border-top: 2px solid #c5bbcb;
                border-bottom: 2px solid #c5bbcb;
                width: 100%;
                margin-left: 50px;
                padding: 20px 0;
            }

        }
    }
}

@media screen and (max-width:1200px) {
    .container {
        flex-direction: column;
        align-items: center;

        .left {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60%;
        }

        .right {
            width: 80%;
        }
    }
}
@media screen and (max-width:900px) {
    .container {
        flex-direction: column;
        align-items: center;

        .left {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 90%;
        }

        .right {
            width: 100%;

            .box {
                width: 100%;

                p {
                    margin: 0;
                }
            }
        }
    }
}

@media screen and (max-width:700px) {
    .container {
        flex-direction: column;

        .left {
            position: relative;
            width: 80%;
            img {
                position: absolute;
                left: -10%;
                top: 35px;
                width: 40%;
                z-index: 999;
            }
        }
        
        .title {
            padding-top: 20%;
            img {
            width: 222px;

            }
        }

        .right {
            display: flex;
            justify-content: center;
            width: 100%;

            .box {
                width: 80%;
                margin-top: 30px;
                padding: 0;

                p {
                    margin: 0;
                }
            }
        }
    }
}</style>